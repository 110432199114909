import { Box, useBreakpointValue } from '@chakra-ui/react';
import dynamic from 'next/dynamic';
import { useTranslation } from '../../lib/hooks.context';
import { mapNewsTeaserProps } from '../../lib/resolvers';
import { NewsTeasers as NewsTeasersProps, MhyChakraProps } from '../../types';
import ArticleGridWrapper from '../ArticleGridWrapper';
import { SectionHeader } from '../BasicBlocks';
import { mhyDateSort } from '../../lib/utils';

const Carousel = dynamic(() => import('../Carousel/Carousel'));
const Teaser = dynamic(() => import('../Teaser'));

const ArrowLink = dynamic(() => import('../ArrowLink'));

const NewsTeasers = ({
  entries,
  all_link: link,
  title,
  ...rest
}: NewsTeasersProps & Omit<MhyChakraProps, 'variant'>) => {
  const isMobile = useBreakpointValue({ md: true });
  const t = useTranslation();
  if (!entries?.length) {
    return null;
  }

  const sortedEntries = [...entries].sort(({ date: a }, { date: b }) =>
    mhyDateSort(a, b),
  );

  return (
    <Box
      as="section"
      gridColumn={['1 / -1', null, '2']}
      {...rest}
      data-test-id="section-news-teasers"
    >
      <SectionHeader title={title} pl={[5, 5, 0]}>
        {link && (
          <ArrowLink href={link.url}>
            {link.title.includes('_') ? t(link.title) : link.title}
          </ArrowLink>
        )}
      </SectionHeader>
      {sortedEntries.length < 4 || !isMobile ? (
        <ArticleGridWrapper colCount="3" itemMinWidth="225px" isOrderedContent>
          {sortedEntries.map((entry) => (
            <Teaser
              key={`${entry.id}-${entry.site}`}
              {...mapNewsTeaserProps(entry, t, false, true)}
              as="li"
            />
          ))}
        </ArticleGridWrapper>
      ) : (
        <Carousel carouselType="teaser">
          {sortedEntries.map((entry) => (
            <Teaser
              key={`${entry.id}-${entry.site}`}
              {...mapNewsTeaserProps(entry, t, false, true)}
            />
          ))}
        </Carousel>
      )}
    </Box>
  );
};

export default NewsTeasers;
