import { ButtonsProps } from '../components/Buttons';
import { TeaserProps } from '../components/Teaser';
import { AcfButtonLink, NewsTeaser, ServicePost, Translate } from '../types';
import { formatPhoneNumber } from './utils';

const teaserArticleHeading = {
  importance: 'h3',
  style: 'h4',
};

export const mapTeaserServiceProps = (
  {
    post_title: title,
    post_excerpt: text,
    featured_image: image,
    url,
  }: ServicePost,
  t: Translate,
): TeaserProps => ({
  buttons: url
    ? [
        {
          link: {
            url,
            title: t('service_teaser_link_title'),
          },
          variant: 'outlineGreen',
        },
      ]
    : false,
  heading: {
    ...teaserArticleHeading,
    style: 'h5',
    title,
  },
  text,
  image,
});

export const mapNewsTeaserProps = (
  { id, title, url, lead, wysiwyg, author, date, ...rest }: NewsTeaser,
  t: Translate,
  isHero?: boolean,
  useLargeButtons?: boolean,
): TeaserProps => ({
  buttons: url
    ? [
        {
          link: {
            url,
            title: t('read_more'),
          },
          variant: isHero ? 'solidGreen' : 'outlineGreen',
          size: isHero || useLargeButtons ? 'large' : undefined,
        },
      ]
    : false,
  heading: {
    ...teaserArticleHeading,
    style: isHero ? 'h3' : 'h4',
    title,
  },
  text: wysiwyg ? undefined : lead,
  wysiwyg: wysiwyg || undefined,
  date: date || undefined,
  ...rest,
  ...(author ? { author } : undefined),
});

export const mapContactButtonsProps = (
  email?: string,
  phone?: string,
): ButtonsProps['buttons'] =>
  [
    {
      link: {
        title: `${email}`,
        url: email ? `mailto:${email}` : undefined,
      },
    },
    {
      link: {
        title: `${phone ? formatPhoneNumber(phone) : undefined}`,
        url: phone ? `tel:${phone}` : undefined,
      },
    },
  ].filter(
    (item: { link: { url?: string } }): item is AcfButtonLink =>
      !!item.link.url,
  );
